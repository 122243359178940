import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Box from '@material-ui/core/Box';

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article
         className='blog-post white-page'
         >
          <header>
          <h2
           className='alt-small-title'
            style={{
                marginTop: '20px',
                marginBottom: 0,
              }}
           >
            {post.frontmatter.topSubtitle}
          </h2>
            <h1
              className='alt-big-title'
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
                ...scale(1.5),
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                // marginBottom: rhythm(1),
              }}
            >
              {post.frontmatter.date}
              &nbsp;&nbsp;&nbsp;
              <i>{post.frontmatter.updated}</i>
            </p>
   
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
            <Bio />
          </footer>
        </article>
        <Box
         bgcolor='secondary.light'
         style={{
          height: '10vh',
         }}
         >
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
        </Box>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        topSubtitle
        title
        date(formatString: "MMMM DD, YYYY")
        updated(formatString: "[*Updated: ]MMMM DD, YYYY")
        description
      }
    }
  }
`
